import React from "react";
import DesktopLogos from '../../images/customers_desktop.png'
import MobileLogos from '../../images/customers_mobile.png'

const CustomerClients = () => (
    <div id="customers">
    <div className="has-text-centered pd-10">
    <br/><br/><br className="is-hidden-mobile"/>
        <h3 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold">Loved by 100+ CHROs across the globe like</h3>
        <br className="is-hidden-mobile"/>
        <div className="column is-11 mar-auto mg-top-15">
            <img src={DesktopLogos} width="100%" className="is-hidden-mobile" alt="company logos"/>
            <img src={MobileLogos} width="100%" className="is-hidden-tablet pd-10" alt="company logos"/>
        </div>
    </div>
    </div>
)

export default CustomerClients

