import React from "react";
import amberMap from '../../images/amber-map.png'

const CustomersMap = () => (
    <div className="has-text-centered">
        <br/><br/>
        <div className="bg-path-map">
        <div className="pd-10">
            <br/>
            <h3 className="is-size-3-tablet is-size-4-mobile has-text-weight-bold">Delivering stellar employee experience beyond borders</h3>
            <br/>
            <p>Trusted by 100+ CHROs, Amber engages over 300,000 employees spread across 50+ countries.</p>
            <br/><br/>
            <div className="pd-20">
                <img src={amberMap} width="100%" alt="world map"/>
            </div>
        </div>
        </div>
    </div>
)

export default CustomersMap;

