import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AmberScore from "../components/reuseable/AmberScore";
import CustomersMap from '../components/customers/CustomersMap';
import CustomersStories from '../components/customers/CustomersStories';
import CustomersClients from '../components/customers/CustomersClients';

const Customers = () => (
  <Layout>
    <SEO title="Amber by inFeedo | Customer love" description="Amber is an AI chatbot that talks to your employees to help you identify those who are unhappy, disengaged or about to leave. She is loved by 100+ CHROs and 300,000+ employee spread across 30+ countries."/>
    <CustomersClients/>
    <CustomersStories/>
    <CustomersMap/>
    <AmberScore/>
  </Layout>
)

export default Customers;